import {Button} from '@telia/styleguide';
import React, {FC, FormEvent} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import * as AppRoutes from '../../appRoutes';
import {FormStateOptions, useFormState} from '../../hooks/useFormState';
import {getLog} from '../../log';
import {ID} from '../../model';
import {FormFc} from '../common/Form';
import FormColumn, {FormColumnSizeDouble} from '../common/FormColumn';
import FormRow from '../common/FormRow';
import PageSubtitle from '../common/PageSubtitle';
import {FieldTypes, FieldWithFormState} from '../common/field';
import {ReportMessageDetails} from './ReportMessageDetails';

const log = getLog('ReportMessageById', 'INFO');

export const ReportMessageByIdFc: FC = (props) => {
  const {messageId} = useParams<{messageId: ID}>() as {messageId: ID};
  const emptyMessageId = messageId === AppRoutes._EMPTY;

  const {formatWithBrand} = AppRoutes.useBrandFormat();
  const navigate = useNavigate();

  const formStateOptions: FormStateOptions = {
    isEditing: true,
    initialEntity: {
      messageId: emptyMessageId ? undefined : messageId,
    },
  };
  const formState = useFormState(formStateOptions);
  const {entity} = formState;

  log.debug('render', {messageId, entity});

  return (
    <>
      <PageSubtitle subtitle="Report Message Details" />
      {entity && (
        <FormFc
          onSubmit={(e: FormEvent) => {
            log.debug('onSubmit');
            entity.messageId &&
              navigate(formatWithBrand(AppRoutes.REPORT_MESSAGE__messageId, entity.messageId as string), {
                replace: true,
              });
          }}
        >
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'messageId'}
                placeholder="Message ID"
                label={undefined}
                type={FieldTypes.input}
              />
            </FormColumn>
            <FormColumn size={FormColumnSizeDouble}>
              <div id="fetchMessageById">
                <Button
                  text={'Fetch message by ID'}
                  kind={Button.kinds.primary}
                  className={'marginTopx'}
                  isDisabled={!entity.messageId}
                  type={Button.types.submit}
                />
              </div>
            </FormColumn>
          </FormRow>
        </FormFc>
      )}
      <br />
      {!emptyMessageId && <ReportMessageDetails message={{messageId}} />}
    </>
  );
};
