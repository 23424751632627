{
  "name": "@telia/cpa-web",
  "version": "1.50.7",
  "description": "Content Provider Access - Frontend",
  "main": "index.js",
  "license": "MIT",
  "private": true,
  "workspaces": [
    "packages/*"
  ],
  "scripts": {
    "auth": "aws codeartifact login --tool npm --repository cpa-libs --domain cpa --duration-seconds 43200 && echo \"always-auth=true\" >> ~/.npmrc",
    "build": "run-s versions build:common build:client build:server",
    "build:client": "cd packages/client && npm run build",
    "build:common": "cd packages/common && npm run build",
    "build:server": "cd packages/server && npm run build",
    "codeMetrics": "ts-node scripts/code_metrics.ts",
    "deepClean": "run-s deepClean:common deepClean:client deepClean:server deepClean:root",
    "deepClean:client": "cd packages/client && npm run deepClean",
    "deepClean:common": "cd packages/common && npm run deepClean",
    "deepClean:server": "cd packages/server && npm run deepClean",
    "deepClean:root": "rimraf ./node_modules",
    "dev:test": "run-p test:watch dev",
    "dev": "run-p dev:common dev:client dev:server",
    "dev:client": "cd packages/client && npm run dev",
    "dev:common": "cd packages/common && npm run dev",
    "dev:server": "cd packages/server && npm run dev",
    "postinstall----skip": "npm run build",
    "prettier": "prettier --write \"**/*\"",
    "start": "cd packages/server && npm run start",
    "test:watch": "run-p test:client test:common test:server",
    "test:client": "npm run test:watch --workspace=@telia/cpa-web-client",
    "test:common": "npm run test:watch --workspace=@telia/cpa-web-common",
    "test:server": "npm run test:watch --workspace=@telia/cpa-web-server",
    "versionBump": "node src/versionBump.js",
    "versions": "node -v && npm -v"
  },
  "devDependencies": {
    "@parcel/transformer-sass": "^2.9.2",
    "@trivago/prettier-plugin-sort-imports": "^4.1.1",
    "buffer": "^6.0.3",
    "husky": "^4.2.3",
    "npm-run-all": "^4.1.5",
    "os-browserify": "^0.3.0",
    "prettier": "^2.0.5",
    "pretty-quick": "^2.0.1",
    "run-s": "^0.0.0",
    "semantic-release": "^24.1.1",
    "typescript": "^4.7.4",
    "vm-browserify": "^1.1.2"
  },
  "resolutions": {},
  "husky": {
    "hooks": {
      "pre-commit": "pretty-quick --staged"
    }
  },
  "engines": {
    "node": ">=20"
  }
}
