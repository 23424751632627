import {constants, period} from '@telia/cpa-web-common';
import {Button} from '@telia/styleguide';
import React, {FC, useMemo, useState} from 'react';
import ReportDcbTable, {AggregatedTransaction, AggregatedTransactionGrouping, sortedFields} from './ReportDcbTable';
import {FieldTypes, FieldWithFormState} from '../common/field';
import Form from '../common/Form';
import FormColumn, {FormColumnSizeDouble} from '../common/FormColumn';
import FormRow from '../common/FormRow';
import PageSubtitle from '../common/PageSubtitle';
import aggregatedTransactionsQuery from '../../graphql/query/aggregatedTransactions.graphql';
import {getLog} from '../../log';
import Loading from '../Loading';
import {useServiceCategories} from '../../hooks/useServiceCategories';
import './ReportDcb.scss';
import {Entity, EntityFieldValue, FormStateOptions, useFormState} from '../../hooks/useFormState';
import {ApolloQueryResult, useApolloClient, gql} from '@apollo/client';

const {balanceTypes, chargeTypes} = constants;

const log = getLog('ReportDcb', 'INFO');

const {isValid} = period;

interface _ReportDcbState {
  aggregatedTransactions?: AggregatedTransaction[];
  loading: boolean;
  editingSearch: boolean;
  error?: ApolloQueryResult<_ReportDcbState>;
}

interface AggregatedTransactionFilter {
  fieldName: AggregatedTransactionGrouping;
  fieldValue: EntityFieldValue;
}

const getGroupedBy: (entity: Entity | undefined) => AggregatedTransactionGrouping[] = (entity) =>
  (entity?.fieldsGrouping || []) as AggregatedTransactionGrouping[];

const getFilters: (entity: Entity | undefined) => AggregatedTransactionFilter[] = (entity) => {
  log.debug('getFilters');
  const groupedBy = getGroupedBy(entity);
  return !entity
    ? []
    : sortedFields
        .filter((f) => groupedBy.includes(f))
        .map((fieldName) => ({fieldName, fieldValue: entity[`${fieldName}Filtering`] || null}))
        .filter(({fieldValue}) => !!fieldValue);
};

const filterAggregatedTransactions: (
  aggregatedTransactions: AggregatedTransaction[] | undefined,
  fieldFilters: AggregatedTransactionFilter[]
) => AggregatedTransaction[] | undefined = (aggregatedTransactions, fieldFilters) => {
  log.debug('filterAggregatedTransactions');
  return aggregatedTransactions?.filter((aggregatedTransaction) =>
    fieldFilters.every(({fieldName, fieldValue}) => fieldValue === aggregatedTransaction[fieldName])
  );
};

const formStateOptions: FormStateOptions = {
  isEditing: true,
  useUrlParams: true,
  preferenceFieldNames: [
    'startDate',
    'endDate',
    'fieldsGrouping',
    'balanceTypeFiltering',
    'chargeTypeFiltering',
    'accountNumberFiltering',
    'serviceCategoryFiltering',
    'categoryTypeFiltering',
  ],
  fixedFields: {},
};

export const ReportDcbFc: FC = (props) => {
  const [state, setState] = useState<_ReportDcbState>({loading: false, editingSearch: true});
  const {loading, editingSearch, aggregatedTransactions} = state;
  const formState = useFormState(formStateOptions);
  const {entity, isEditing, onSaved, onCancel, onEdit, onChange, clearErrors, putError} = formState;
  const {serviceCategories, serviceCategoryDisplayName, categoryTypeDisplayName} = useServiceCategories();
  const fieldFilters: AggregatedTransactionFilter[] = useMemo(() => getFilters(entity), [entity]);
  const filteredAggregatedTransactions = useMemo(
    () => filterAggregatedTransactions(aggregatedTransactions, fieldFilters),
    [aggregatedTransactions, fieldFilters]
  );
  const apolloClient = useApolloClient();

  const editSearch = () => {
    setState({loading: false, editingSearch: true, aggregatedTransactions: undefined});
  };

  const fetchAggregatedTransactions = () => {
    if (!entity) {
      return;
    }
    const {startDate, endDate, fieldsGrouping} = entity;
    const parameters: Entity = {startDate, endDate};
    log.info('Fetch aggregatedTransactions', {...parameters});
    clearErrors();
    if (!startDate) {
      putError('startDate', 'Start date is required');
      return;
    }
    if (!endDate) {
      putError('endDate', 'End date is required');
      return;
    }
    if (!isValid(parameters)) {
      putError('startDate', 'Invalid search period');
      putError('endDate', 'Invalid search period');
      return;
    }

    log.info('Fetch aggregated transactions', parameters);
    setState({loading: true, editingSearch: false});
    apolloClient
      .query({
        query: gql(aggregatedTransactionsQuery),
        variables: {
          ...parameters,
        },
      })
      .then((res: ApolloQueryResult<_ReportDcbState>) => {
        log.debug('resolved fetch aggregatedTransactions', res);
        setState({
          loading: false,
          editingSearch: false,
          aggregatedTransactions: res.data.aggregatedTransactions,
        });
      })
      .catch((error: ApolloQueryResult<_ReportDcbState>) => {
        setState({loading: false, editingSearch: false, aggregatedTransactions: [], error});
      });
    return null;
  };

  log.debug('render', {serviceCategories, props, state});

  const isGroupingBy: (field: AggregatedTransactionGrouping) => boolean = (field) =>
    getGroupedBy(entity).includes(field);

  const filterDisplayName: (filter: AggregatedTransactionFilter) => string = ({fieldName, fieldValue}) => {
    switch (fieldName) {
      case 'serviceCategory': {
        return serviceCategoryDisplayName(fieldValue as string);
      }
      case 'categoryType': {
        return categoryTypeDisplayName(entity?.serviceCategoryFiltering as string, fieldValue as string);
      }
      default: {
        return fieldValue as string;
      }
    }
  };

  return (
    <div id={'reportDcb'} className={'form--column--margin--normal'}>
      <PageSubtitle subtitle="Direct Charging and Billing" />
      {entity && (
        <Form>
          <FormRow>
            <FormColumn>
              <FieldWithFormState
                formState={formState}
                entityFieldId={'startDate'}
                label="From date"
                type={FieldTypes.date}
                defaultValue={'since ever'}
                isEditing={editingSearch}
              />
              <FieldWithFormState
                formState={formState}
                entityFieldId={'endDate'}
                label="To date"
                type={FieldTypes.date}
                defaultValue={'no end'}
                isEditing={editingSearch}
              />
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn size={FormColumnSizeDouble}>
              <div id="fetchStatistics">
                {editingSearch ? (
                  <Button
                    onClick={fetchAggregatedTransactions}
                    text={'Fetch Aggregated Transactions'}
                    kind={Button.kinds.primary}
                    className={'marginTop'}
                  />
                ) : (
                  <Button
                    onClick={editSearch}
                    text={'Edit search'}
                    kind={Button.kinds.normal}
                    className={'marginTop'}
                  />
                )}
              </div>
            </FormColumn>
          </FormRow>

          {aggregatedTransactions && (
            <>
              <FormRow>
                <FormColumn size={FormColumnSizeDouble}>
                  <FieldWithFormState
                    label={'Field grouping'}
                    formState={formState}
                    entityFieldId={'fieldsGrouping'}
                    type={FieldTypes.multi}
                    options={[
                      {id: 'balanceType', name: 'Debit/Credit'},
                      {id: 'chargeType', name: 'Pre/Postpaid'},
                      {id: 'accountNumber', name: 'Account number'},
                      {id: 'serviceCategory', name: 'Service category'},
                      {
                        id: 'categoryType',
                        name: 'Category type',
                        description: isGroupingBy('serviceCategory')
                          ? undefined
                          : `Only when grouping by 'Service category'`,
                        isDisabled: !isGroupingBy('serviceCategory'),
                      },
                    ]}
                    className={'noWrap'}
                    isEditing={true}
                    onChangeAlso={(grouping: string[]) =>
                      grouping.includes('serviceCategory') ||
                      onChange('fieldsGrouping')(grouping.cloneExcluding('categoryType'))
                    }
                  />
                  <FieldWithFormState
                    label={'Balance type filtering'}
                    formState={formState}
                    entityFieldId={'balanceTypeFiltering'}
                    type={FieldTypes.select}
                    options={balanceTypes}
                    className={'noWrap'}
                    isEditing={true}
                    isNullable={true}
                    isDisabled={!isGroupingBy('balanceType')}
                    tip={!isGroupingBy('balanceType') && `Group by 'Debit/Credit' to enable filtering`}
                  />
                </FormColumn>

                <FormColumn size={FormColumnSizeDouble}>
                  <FieldWithFormState
                    label={'Charge type filtering'}
                    formState={formState}
                    entityFieldId={'chargeTypeFiltering'}
                    type={FieldTypes.select}
                    options={chargeTypes}
                    className={'noWrap'}
                    isEditing={true}
                    isNullable={true}
                    isDisabled={!isGroupingBy('chargeType')}
                    tip={!isGroupingBy('chargeType') && `Group by 'Pre/Postpaid' to enable filtering`}
                  />
                  <FieldWithFormState
                    label={'Account num. filtering'}
                    formState={formState}
                    entityFieldId={'accountNumberFiltering'}
                    type={FieldTypes.input}
                    className={'noWrap'}
                    isEditing={true}
                    isDisabled={!isGroupingBy('accountNumber')}
                    tip={!isGroupingBy('accountNumber') && `Group by 'Account number' to enable filtering`}
                  />
                </FormColumn>
              </FormRow>

              <FormRow>
                <FormColumn size={FormColumnSizeDouble}>
                  <FieldWithFormState
                    label={'Service category filtering'}
                    formState={formState}
                    entityFieldId={'serviceCategoryFiltering'}
                    type={FieldTypes.select}
                    options={serviceCategories?.map(({id}) => ({id, name: serviceCategoryDisplayName(id)}))}
                    className={'noWrap'}
                    isEditing={true}
                    isNullable={true}
                    isDisabled={!isGroupingBy('serviceCategory')}
                    tip={!isGroupingBy('serviceCategory') && `Group by 'Service category' to enable filtering`}
                    onChangeAlso={() => onChange('categoryTypeFiltering')(null)}
                  />
                </FormColumn>
                <FormColumn size={FormColumnSizeDouble}>
                  <FieldWithFormState
                    label={'Category type filtering'}
                    formState={formState}
                    entityFieldId={'categoryTypeFiltering'}
                    type={FieldTypes.select}
                    defaultValue={entity?.serviceCategoryFiltering ? undefined : `Select 'Service category'`}
                    options={serviceCategories
                      ?.filter(({id: serviceCategoryId}) => serviceCategoryId === entity?.serviceCategoryFiltering)
                      .reduce(
                        (_, {id: serviceCategoryId, types}) =>
                          types.map(({id: comboId, typeId}) => ({
                            id: typeId,
                            name: categoryTypeDisplayName(serviceCategoryId, typeId),
                          })),
                        []
                      )}
                    className={'noWrap'}
                    isEditing={true}
                    isNullable={true}
                    isDisabled={!isGroupingBy('categoryType')}
                    tip={!isGroupingBy('categoryType') && `Group by 'Category type' to enable filtering`}
                  />
                </FormColumn>
              </FormRow>
            </>
          )}
        </Form>
      )}

      {loading && <Loading />}
      {filteredAggregatedTransactions && (
        <>
          {fieldFilters && !fieldFilters.isEmpty() && (
            <div id={'filters'}>
              Filters:
              {fieldFilters.map(({fieldName, fieldValue}) => (
                <span className={'filter'} key={fieldName}>
                  {filterDisplayName({fieldName, fieldValue})}
                </span>
              ))}
            </div>
          )}
          <ReportDcbTable
            loading={loading}
            aggregatedTransactions={filteredAggregatedTransactions}
            groupBy={getGroupedBy(entity)}
          />
        </>
      )}
    </div>
  );
};
